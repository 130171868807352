import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { DialogAlertComponent } from 'app/dialogs/dialog-alert/dialog-alert.component';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private translate: TranslateService,
    private router: Router,
    private dialogService: NbDialogService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(catchError(err => {
        try {
          if (err.status === 403 && err.error && err.error.error && err.error.error.message === 'Access Denied') {
            this.dialogService.open(DialogAlertComponent, {
              context: {
                  title: 'ERROR',
                  text: err.error.error.message,
                  okButton: 'Ok',
              },
            }).onClose.subscribe(() => {
                const url = '';
                this.router.navigate([url]);
            });
          }

          if ( err.status === 401 && err.error && err.error.error && err.error.error.code === 'AUTHORIZATION_REQUIRED' ) {
            this.dialogService.open(DialogAlertComponent, {
              context: {
                  title: 'ERROR',
                  text: err.error.error.message,
                  okButton: 'Ok',
              },
            }).onClose.subscribe(() => {
                const url = '';
                this.router.navigate([url]);
            });
          }

          if (err.status === 500) {
            this.dialogService.open(DialogAlertComponent, {
              context: {
                  title: 'ERROR',
                  text: err.error.error.message,
                  okButton: 'Ok',
              },
            }).onClose.subscribe(() => {
                // const url = '';
                // this.router.navigate([url]);
            });
          }

          if (err.status === 422) {
            this.dialogService.open(DialogAlertComponent, {
              context: {
                  title: 'ERROR',
                  text: this.translate.instant('errors.errorData.text'),
                  okButton: 'Ok',
              },
            }).onClose.subscribe(() => {
                // const url = '';
                // this.router.navigate([url]);
            });
          }

          if (err.status === 409) {
            let text = this.translate.instant('errors.errorData.text');
            if (err.error.error.name !== 'GEOPOINT') {
              text = this.translate.instant('errors.errorGeopoint.text');
            }
            this.dialogService.open(DialogAlertComponent, {
              context: {
                  title: 'ERROR',
                  text: text,
                  okButton: 'Ok',
              },
            }).onClose.subscribe(() => {
                // const url = '';
                // this.router.navigate([url]);
            });
          }

          if (err.status === 400 && err.error.error.name !== 'USER_NOT_ALLOWED') {

            let title, text;
            switch (err.error.error.name) {
              case 'DUPLICATE_NAME_EVENT':
                title = this.translate.instant('errors.duplicateNameEvent.title');
                text = this.translate.instant('errors.duplicateNameEvent.text');
                break;
              case 'DUPLICATE_NAME_POI':
                title = this.translate.instant('errors.duplicateNamePOI.title');
                text = this.translate.instant('errors.duplicateNamePOI.text');
                break;
              case 'DUPLICATE_NAME_INFO':
                title = this.translate.instant('errors.duplicateNameInfo.title');
                text = this.translate.instant('errors.duplicateNameInfo.text');
                break;
              case 'DELETE_POI_IN_CIRCUIT':
                title = this.translate.instant('errors.deletePOIInCircuit.title');
                text = this.translate.instant('errors.deletePOIInCircuit.text');
                break;
              case 'INACTIVE_POI_IN_CIRCUIT':
                title = this.translate.instant('errors.inactivePOIInCircuit.title');
                text = this.translate.instant('errors.inactivePOIInCircuit.text');
                break;
              default:
                break;
            }

            this.dialogService.open(DialogAlertComponent, {
              context: {
                  title: title,
                  text: text,
                  okButton: 'Ok',
              },
            }).onClose.subscribe(() => {
                // const url = '';
                // this.router.navigate([url]);
            });
          }
        } catch (exception) {
          // Programming error
        }

        const error = err.error.message || err.statusText;
        return throwError(err);
    }));
  }
}
