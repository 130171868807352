import { UserInterface } from 'app/interfaces/user.interface';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { NbToastrService } from '@nebular/theme';
import { environment } from 'environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  public myForm: FormGroup;
  private token;
  public account_validation_messages = {
    'username': [
      { type: 'required' },
    ],
    'password': [
      { type: 'required' },
    ],
  };
  loading = false;

  constructor(
    public formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService,
    private toastrService: NbToastrService,
    private reCaptchaV3Service: ReCaptchaV3Service,
  ) {
    this.translate.setDefaultLang('es');
   }

  ngAfterViewInit() {
  }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.paramMap.get('token');

    const json = {
      username: ['', Validators.required ],
      password: ['', Validators.compose([
        Validators.required,
      ])],
    };

    this.myForm = this.formBuilder.group(json);
  }

  login() {
    this.loading = true;
    this.disableForm(true);
    const self = this;
    this.reCaptchaV3Service.execute('loginAction')
    .subscribe((token) => {
      this.authService.login(this.myForm.get('username').value, this.myForm.get('password').value, token).then(function(data: UserInterface) {
        if (data) {
          self.loading = false;
          self.disableForm(false);
          data.picture = data.picture ? environment.userImgPath + data.picture : null;
          self.redirectAfterLogin(data);
        }
      }, function(error) {
        if (error && error.error.error.name !== 'USER_NOT_ALLOWED') {
            self.toastrService.show(
              'Usuario o contraseña incorrectos', 'Error', { duration: 5000, status: 'danger' });
        } else {
          self.toastrService.show(
            'El acceso ha sido denegado', 'Error', { duration: 5000, status: 'danger' });
        }
        self.loading = false;
        self.disableForm(false);
      });
    });
  }

  disableForm(value) {
    if (value) {
      (<HTMLInputElement> document.getElementById('inputUsername')).disabled = true;
      (<HTMLInputElement> document.getElementById('inputPass')).disabled = true;
      (<HTMLInputElement> document.getElementById('cancelButton')).disabled = true;
      (<HTMLInputElement> document.getElementById('loginButton')).disabled = true;
    } else {
      (<HTMLInputElement> document.getElementById('inputUsername')).disabled = false;
      (<HTMLInputElement> document.getElementById('inputPass')).disabled = false;
      (<HTMLInputElement> document.getElementById('cancelButton')).disabled = false;
      (<HTMLInputElement> document.getElementById('loginButton')).disabled = false;
    }
  }

  resetForm(): void {
    this.myForm.reset();
  }

  redirectAfterLogin(userData) {
    // this.router.navigate(['/pages/map']);
    // se cambia la redireccion para que se muestren los nb-card-title
    // ver https://github.com/akveo/nebular/issues/2755
    window.location.href = '/#/pages/map';
  }

}

export function matchOtherValidator(otherControlName: string) {

  let thisControl: FormControl;
  let otherControl: FormControl;

  return function matchOtherValidate(control: FormControl) {

    if (!control.parent) {
      return null;
    }

    // Initializing the validator.
    if (!thisControl) {
      thisControl = control;
      otherControl = control.parent.get(otherControlName) as FormControl;
      if (!otherControl) {
        throw new Error('matchOtherValidator(): other control is not found in parent group');
      }
      otherControl.valueChanges.subscribe(() => {
        thisControl.updateValueAndValidity();
      });
    }
    if (!otherControl) {
      return null;
    }
    if (otherControl.value !== thisControl.value) {
      return {
        matchOther: true,
      };
    }
    return null;
  };
}
