import { DataApiService } from 'app/services/data-api.service';
import { AuthService } from './auth.service';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpEvent, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
    providedIn: 'root',
})
export class PointOfInterestService {

    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private authService: AuthService,
        private dataApiService: DataApiService,
    ) { }

    getToken = function () {
        return this.authService.getAccessToken();
    };

    getUserId = function () {
        return this.authService.getUserId();
    };

    respond(res, data, operation, type) {
        switch (res.type) {
            case HttpEventType.Sent:
                const progresSent = {
                    operation: operation,
                    name: data.name,
                    id: data.id,
                    type: type,
                    status: 'start',
                    progress: 0,
                };
                this.dataApiService.emitProgressBar(progresSent);
                break;
            case HttpEventType.UploadProgress:
                const progress = Math.round(100 * res.loaded / res.total);
                const progres = {
                    operation: operation,
                    name: data.name,
                    id: data.id,
                    type: type,
                    status: 'progress',
                    progress: progress,
                };
                this.dataApiService.emitProgressBar(progres);
                break;
            case HttpEventType.ResponseHeader:
                const responce = {
                    operation: operation,
                    name: data.name,
                    id: data.id,
                    type: type,
                    status: 'ok',
                    progress: 100,
                };
                if (res.status !== 200) {
                    responce.status = 'error';
                }
                this.dataApiService.emitProgressBar(responce);
                break;
            case HttpEventType.DownloadProgress:
                break;
            case HttpEventType.Response:
                return res.body;
            default:
                break;
        }
    }

    createPointOfInterest(data) {
        const url = environment.rootpath + 'secure/point-of-interest-service/create-point-of-interest';
        return this.http.post<any>(url, data, {
            reportProgress: true,
            observe: 'events',
        }).pipe(map((res) => {
            return this.respond(res, data, 'create', 'point-of-interest');
        }));
    }

    editPointOfInterest(poiId, data) {
        const url = environment.rootpath + 'secure/point-of-interest-service/' + poiId + '/edit-point-of-interest';
        return this.http.post<any>(url, data, {
            reportProgress: true,
            observe: 'events',
        }).pipe(map((res) => {
            return this.respond(res, data, 'edit', 'point-of-interest');
        }));
    }

    logicDeletePointOfInterest(poiId) {
        const url = environment.rootpath + 'secure/point-of-interest-service/logic-delete-point-of-interest';
        return this.http.post<any>(url, {poiId})
            .pipe(map((res) => res));
    }

    getPaginatedPointsOfInterest(filter, limit, skip, sortColumn: string, order: string) {
        const url = environment.rootpath + 'secure/point-of-interest-service/paginated-point-of-interest?filter='
        + filter + '&limit=' + limit + '&skip=' + skip + '&sortColumn=' + sortColumn + '&order=' + order;
        return this.http.get<any>(url)
            .pipe(map((res) => res));
    }

    getPointsOfInterest(filter) {
        const url = environment.rootpath + 'secure/point-of-interest-service/points-of-interest?filter=' + filter;
        return this.http.get<any>(url)
            .pipe(map((res) => res));
    }

    getPointsForMap(filter) {
        const url = environment.rootpath + 'secure/point-of-interest-service/points-for-map?filter=' + filter;
        return this.http.get<any>(url)
            .pipe(map((res) => res));
    }

    getPOIMultimedia(poiId) {
        const url = environment.rootpath + 'secure/point-of-interest-service/' + poiId + '/points-of-interest-multimedia';
        return this.http.get<any>(url)
            .pipe(map((res) => res));
    }

}
