import { Injectable, EventEmitter, Output} from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserLogged } from '../dtos/user-logged';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
  ) { }

  @Output() userLogged = new EventEmitter();

  @Output() changeLang: EventEmitter<any> = new EventEmitter();
  @Output() navbarOpen = new EventEmitter();
  @Output() SearchText = new EventEmitter();

  emitNavbarOpen(navbarOpenValue) {
    this.navbarOpen.emit(navbarOpenValue);
  }

  emitChangeLang(lang) {
    this.changeLang.emit(lang);
  }

  emitChangeSearchText(text) {
    this.SearchText.emit(text);
  }

  emitUserLogged() {
    return this.userLogged.emit();
  }

  setLocalStorage(key, data) {
    localStorage.setItem(key, data);
  }

  getLocalStorage(key) {
    return localStorage.getItem(key);
  }

  clearUserData() {
    this.logout();
    localStorage.clear();
    this.cookieService.delete('access_token');
    this.cookieService.delete('id');
    this.cookieService.delete('userId');
  }

  getUserId() {
    const userLogger = this.getUser();
    return ((userLogger && userLogger.user.id) ? userLogger.user.id : null);
  }

  getAccessToken() {
    const user = this.getUser();
    if (user) {
      return user.accessToken;
    } else {
      return null;
    }
  }

  setAccessToken(token) {
    const user = this.getUser();
    if (user) {
      user.accessToken = token;
      this.setLoggedUser(user);
    }
  }

  login(user, pass, token) { // , captcha) {
    const self = this;
    return new Promise(function(resolve, reject) {
      const data = {
        username: user.toLowerCase(),
        password: pass,
        'g-recaptcha-response': token,
      };
      const url = environment.rootpath + 'ext/public-user-services/login';
      self.http.post<any>(url, data).subscribe((res) => {
        self.createUserFromLogin(res).then((aUser) => {
          resolve(aUser);
        }, (error) => {
          self.clearUserData();
          reject(error);
        });
      }, (error) => {
        self.clearUserData();
        reject(error);
      });
    });
  }

  createUserFromLogin = function(res) {
    const self = this;
    return new Promise(function(resolve, reject) {
      const userLogged = new UserLogged();
      userLogged.accessToken = res.id;
      userLogged.user = res.user;
      self.setLoggedUser(userLogged);
      resolve(userLogged);
    });
  };

  setLoggedUser(user: UserLogged) {
    this.setLocalStorage('userLogged', JSON.stringify(user));
  }

  getUser() {
    let user = new UserLogged;
    user = JSON.parse(this.getLocalStorage('userLogged'));
    return (user ? user : null);
  }

  logout() {
    const self = this;
    return new Promise<void>(function(resolve, reject) {
      const accessToken = self.getAccessToken();
      if (accessToken) {
        const url = environment.rootpath + 'ext/public-user-services/logout';
        const data = {
          accessToken: accessToken,
        };
        self.http.post<any>(url, data).subscribe((res) => {
          resolve(res);
        }, (error) => {
          reject(error);
        });
      } else {
        resolve();
      }
    });
  }

  isLogged() {
    const user = this.getUser();
    if (user) {
      return new Observable((observer) => {
        observer.next(user);
        observer.complete();
      });
    } else {
      return new Observable((observer) => {
        this.clearUserData();
        observer.error();
      });
    }
  }

  resetPassword(newPassword, accessToken) {
    const data = {
      newPassword: newPassword,
      accessToken: accessToken,
    };
    const url = environment.rootpath + 'ext/public-user-services/reset-password-user';
    return this.http.post<any>(url, data)
    .pipe(map((res) => {
      return res;
    }));
  }

  reset(email) {
    const data = {
      email: email,
    };
    const url = environment.rootpath + 'ext/public-user-services/reset';
    return this.http.post<any>(url, data)
    .pipe(map((res) => {
      return res;
    }));
  }

  userEmail(accessToken) {
    const data = {
      accessToken: accessToken,
    };
    const url = environment.rootpath + 'ext/public-user-services/user-email';
    return this.http.post<any>(url, data)
    .pipe(map((res) => {
      return res;
    }));
  }

}
