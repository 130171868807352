<div class="row justify-content-center align-items-center">
  <img class="banner" src="../../../assets/images/LoginBanner.png">
</div>
<div class="row justify-content-center align-items-center">
  <img class="logo" src="../../../assets/images/isotipo-dark.png">
</div>
<div class="row justify-content-center align-items-center">
  <h1 class="title">{{ 'login.title' | translate }}</h1>
</div>
<div class="row">
  <div class="col-12">
    <form [formGroup]="myForm">
      <div id="inputs">
        <div class="margins row col-md-12 justify-content-center align-items-center">
          <input id="inputUsername"class="width input" placeholder="{{ 'login.label.username' | translate }}"
            formControlName="username" required>
        </div>
        <div class="row col-md-12 justify-content-center align-items-center">
          <div *ngFor="let validation of account_validation_messages.username">
            <div class="error-message"
              *ngIf="myForm.get('username').hasError(validation.type) && (myForm.get('username').dirty || myForm.get('username').touched)">
              {{ 'login.error.' + validation.type | translate }}
            </div>
          </div>
        </div>
        <div class="margins row col-md-12 justify-content-center align-items-center">
          <input id="inputPass" class="width input" type="password" placeholder="{{ 'login.label.password' | translate }}"
            formControlName="password" required>
        </div>
        <div class="row col-md-12 justify-content-center align-items-center">
          <div *ngFor="let validation of account_validation_messages.password">
            <div class="error-message"
              *ngIf="myForm.get('password').hasError(validation.type) && (myForm.get('password').dirty || myForm.get('password').touched)">
              {{ 'login.error.' + validation.type | translate }}
            </div>
          </div>
        </div>
        <div class="row col-md-12 justify-content-center align-items-center margins">
          <a id="forgotPass" href="./#/auth/restore;forgot=true;token=-1" disabled="loading">{{ 'login.label.forgot-password' | translate }}</a>
        </div>
      </div>
      <div id="buttons" class="row col-md-12 justify-content-center align-items-center margin-buttons">
        <div class="col-6">
          <button id="cancelButton" type="reset" class="btn btn-primary float-right cancel-button" role="button" (click)="resetForm()">
            {{ 'common.buttons.cancel' | translate }}
          </button>
        </div>
        <div class="col-6">
          <button id="loginButton" type="submit" class="btn btn-primary float-left send-button" role="button" [disabled]="!myForm.valid"
            (click)="login()" [nbSpinner]="loading" nbSpinnerStatus="warning">{{ 'login.buttons.login' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="row col-12 justify-content-center align-items-center text-center mt-5">
    <a target="_blank" href="http://www.estratec.com.ar">
      <img class="img-responsive center-block" style="width: 25%;" src="../../../assets/images/powerByEstratec.jfif" alt="http://www.estratec.com.ar">
    </a>
  </div>
</div>
