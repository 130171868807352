import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../services/auth.service';
import { environment } from '../../environments/environment';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { throwError } from 'rxjs';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = request.url;
    if (url.indexOf(environment.rootpath) === 0) {
      if (url.indexOf('secure/') >= 0 ) {
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      // Bearer
        const at = this.authService.getAccessToken();
        if (at) {
          if (isSafari) {
            request = request.clone({
              setParams: {
                access_token: `${at}`,
              },
            });
          } else {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${at}`,
              },
            });
          }
        }
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: `Basic ${environment.basicAuth}`,
          },
        });
      }
    }
    return next.handle(request).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
      }
    }, (response: any) => {
      /* if (response instanceof HttpErrorResponse) {
        if (response.status===401) {
          this.AuthService.clearUserData();
          this.router.navigate(['home']);
        }
      } */
      return throwError(response);
    });
  }
}
