    <div class="col sub-subtitle-row">
        <div class="row">
            <div class="col sub-subtitle">
                <span>
                    <h5>Términos y condiciones</h5>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <hr class="gray-line">
            </div>
        </div>

        <div class="row confirmation ">
            <ol class="col">
                <li>
                    <h5  id=privacidad>Política de privacidad</h5>

                    <p> A través de esta aplicación no se recaban datos de carácter personal de los usuarios. </p>

                    <p> No se registran direcciones IP. </p>

                    <p> No se accede a las cuentas de correo de los usuarios. </p>

                    <p> La aplicación no guarda información relativa a tu dispositivo como, por ejemplo, fallos,
                        actividad del sistema, ajustes del hardware, tipo de navegador, idioma del navegador. </p>

                    <p> La aplicación no accede a tus contactos ni agendas. </p>

                    <p> La aplicación no recopila información sobre tu ubicación real. </p>

                    <p> Remarketing con Google AdMob

                        Proveedores como Google, utilizan cookies de primer nivel y cookies de terceros u otros
                        identificadores de terceros para compilar datos sobre las interacciones de los usuarios con las
                        impresiones de anuncios y otras funciones de servicio de anuncios. </p>

                    <p> Clasificación por edades: PEGI 3 - Apto para todos los públicos. </p>

                    <p> SmartTour es el único responsable de SmartTour y su contenido. </p>

                    <p> Mantenimiento y Soporte: SmartTour estará obligado a proporcionar dicho
                        mantenimiento o soporte. </p>

                    <p> Cargos y cuotas: Cualquier uso de esta aplicación es totalmente gratuito. </p>

                    <p> Cambios en nuestra Política de Privacidad:

                        Nuestra Política de Privacidad puede cambiar de vez en cuando.

                        Publicaremos cualquier cambio de política de privacidad en esta página, por lo que debe
                        revisarla periódicamente. </p>

                </li>

                <li>
                    <h5  id=privacidad>Datos Personales y Privacidad</h5>

                    <ul style="list-style-type: lower-alpha; font-weight: normal !important;">
                        <li>
                            <h3 class="sub-subtitle">Uso y confidencialidad</h3>
                        </li>

                        <p>La información y datos que proporciones voluntariamente a través de SmartTour se incluirán en una base de datos, con el objetivo de proporcionarte cada vez un mejor servicio. Tus datos también podrán utilizarse para elaborar estadísticas y estudios de mercado. Asimismo, el registro en el sistema implicará tu autorización para que te enviemos información de interés o promocional. La aceptación de estos Términos y Condiciones, implica que prestas tu consentimiento expreso, libre e informado para la inclusión, uso y tratamiento de tus datos personales.</p>
                        <p>El tratamiento de tus datos personales se realizará en un todo de acuerdo con las disposiciones de la Ley 25.326 sobre Protección de los Datos Personales y demás normas vigentes en la materia, adoptando los recaudos técnicos y operativos necesarios en lo que respecta a la custodia, almacenamiento, conservación, y confidencialidad de la información con el fin de evitar su alteración, pérdida, tratamiento o acceso no autorizado.</p>
                        <p>Debido a que no es posible garantizar totalmente la seguridad en las comunicaciones entre el servidor y el cliente, SmartTour no puede dar garantía que la información transmitida a través de internet se encuentra completamente segura. Por lo que, aceptando estos términos asumes este riesgo y declaras conocerlo y aceptarlo, deslindándonos de responsabilidad por cualquier daño y/o perjuicio que sufras al respecto.</p>

                        <li class="mt-3">
                            <h3 class="sub-subtitle">Derecho de acceso, rectificación y supresión</h3>
                        </li>

                        <p>Si deseas ponerte en contacto con nosotros respecto al uso de tus Datos Personales o bien ejercer tus derechos de acceso, rectificación y supresión al tratamiento de tus Datos Personales, por favor envía un correo electrónico a <a class="font-italic" href="mailto:contacto@estratec.com.ar?Subject=Consulta">contacto@estratec.com.ar</a>.</p>


                    </ul>    
                </li>

            </ol>
        </div>
        <div class="row">
            <div class="col">
                <hr class="red-line">
            </div>
        </div>
    </div>