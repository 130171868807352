import { Component, OnInit } from '@angular/core';
import { DataApiService } from 'app/services/data-api.service';

@Component({
  selector: 'ngx-load-bar',
  templateUrl: './load-bar.component.html',
  styleUrls: ['./load-bar.component.scss'],
})
export class LoadBarComponent implements OnInit {

  progresbars: any[] = [];

  constructor(
    private dataApiService: DataApiService,
  ) {
  }

  ngOnInit() {
    this.dataApiService.progressBar.subscribe((result) => {
      if (result) {
        switch (result.status) {
          case 'start':
            if (this.progresbars && this.progresbars.length === 0) {
              this.dataApiService.emitShowBar(true);
            }
            this.progresbars.push(result);
            break;
          case 'progress':
            this.progresbars.forEach(progressbar => {
              if ((progressbar.id && progressbar.id === result.id) || progressbar.name === result.name && progressbar.type === result.type) {
                progressbar.progress = result.progress;
              }
            });
            break;
          case 'ok':
            this.progresbars.forEach(progressbar => {
              if ((progressbar.id && progressbar.id === result.id ) || progressbar.name === result.name && progressbar.type === result.type) {
                progressbar.status = result.status;
              }
            });
            break;
          case 'error':
          this.progresbars.forEach(progressbar => {
            if ((progressbar.id && progressbar.id === result.id ) || progressbar.name === result.name && progressbar.type === result.type) {
              progressbar.status = result.status;
            }
          });
          break;
          default:
            break;
        }
      }
    });
  }

  closeBar(bar) {
    const index = this.progresbars.indexOf(bar);
    if (index > -1) {
      this.progresbars.splice(index, 1);
    }
    if (this.progresbars && this.progresbars.length === 0) {
      this.dataApiService.emitShowBar(false);
    }
  }
}
