<div class="row justify-content-center align-items-center">
  <img class="banner" src="../../../assets/images/LoginBanner.png">
</div>
<div class="row mt-5">
  <div class="col text-center title">
    <h1>{{ 'success-page.message1' | translate }}</h1>
  </div>
</div>
<div class="row">
  <div class="col text-center title">
    <h3>{{ 'success-page.message2' | translate }}</h3>
  </div>
</div>