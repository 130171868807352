import { AuthService } from './auth.service';
import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/internal/operators/map';
import { Output } from '@angular/core';
import { UserLogged } from 'app/dtos/user-logged';

@Injectable({
    providedIn: 'root',
})
export class UserService {

    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private authService: AuthService,
    ) { }

    @Output() userChange: EventEmitter<any> = new EventEmitter();

    emitUserChange(user) {
        this.userChange.emit(user);
    }

    setLoggedUser(user: UserLogged) {
        const accesstoken = this.authService.getAccessToken();
        const userlogged = {
            user: user,
            accessToken: accesstoken,
        };
        this.authService.setLocalStorage('userLogged', JSON.stringify(userlogged));
        this.emitUserChange(userlogged);
    }

    setUserImg(imgPath: string) {
        const userlogged = this.authService.getUser();
        userlogged.user.picture = imgPath;
        this.authService.setLocalStorage('userLogged', JSON.stringify(userlogged));
        this.emitUserChange(userlogged);
    }

    editUser(userId, data) {
        const url = environment.rootpath + 'secure/user-services/' + userId + '/edit-user';
        return this.http.post<any>(url, data)
            .pipe(map((res) => res));
    }

    changePassword(data) {
        const userId = this.authService.getUserId();
        const url = environment.rootpath + 'secure/user-services/' + userId + '/change-password';
        return this.http.post<any>(url, data)
            .pipe(map((res) => res));
    }

    uploadUserImage(userId, data) {
        const token = this.authService.getAccessToken();
        const url = environment.rootpath + 'secure/user-services/' + userId + '/upload-user-image';
        return this.http.post<any>(url, data)
            .pipe(map((res) => res));
    }

    deleteUserImage(userId) {
        const url = environment.rootpath + 'secure/user-services/' + userId + '/delete-user-image';
        return this.http.get<any>(url)
            .pipe(map((res) => res));
    }
}
