import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators';
import { AuthService } from './services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate() {
      const authenticated = this.authService.getAccessToken();
      if (!authenticated) {
        this.router.navigate(['auth/login']);
        return false;
      }
      return true;
  }
}
