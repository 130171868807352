import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbDialogService, NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { Router } from '@angular/router';

import { UserData } from '../../../@core/data/users';
import { map, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserInterface } from 'app/interfaces/user.interface';
import { UserService } from 'app/services/user.service';
import { AuthService } from './../../../services/auth.service';
import { environment } from '../../../../environments/environment';
import { DialogInfoComponent } from 'app/dialogs/dialog-info/dialog-info.component';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: UserInterface;
  picturePath: string = '';
  noImgPath: string = environment.noImgPath;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [
    { title: this.translate.instant('header.label.profile'),
      icon: 'person-outline',
      link: '/pages/profile',
    },
    { title: this.translate.instant('header.label.log-out'),
      icon: 'log-out-outline',
    },
  ];

  recordsSub: Subscription;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private internalUserService: UserService,
              private authService: AuthService,
              private translate: TranslateService,
              private breakpointService: NbMediaBreakpointsService,
              private dialogService: NbDialogService,
              private router: Router) {
  }

  ngOnInit() {
    this.recordsSub = this.menuService.onItemClick().subscribe(( event ) => {
        this.onItemSelection(event.item.title);
      });

    this.currentTheme = this.themeService.currentTheme;
    /* this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick); */

    this.noImgPath = environment.noImgPath;

    this.user = this.authService.getUser().user;
    if (this.user.picture) {
      if (this.user.picture.indexOf('http') === -1) {
        this.picturePath = environment.userImgPath;
      }
    }

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.internalUserService.userChange.subscribe((result) => {
      if (result.user.picture) {
        if (result.user.picture.indexOf('http') === -1) {
          this.picturePath = environment.userImgPath;
        }
      }
      this.user = result.user;
    });
  }

  onItemSelection( title ) {
    if ( title === 'Salir' ) {
      this.doLogout();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.recordsSub.unsubscribe();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  info() {
    this.dialogService.open(DialogInfoComponent);
  }

  doLogout() {
    this.authService.clearUserData();
    this.router.navigate(['auth/login']);
  }

}
