import { NgModule } from '@angular/core';

import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonModule } from '@angular/common';
import { TokenInterceptor } from 'app/interceptors/token.interceptor';
import { ErrorInterceptor } from 'app/interceptors/error.interceptor';
import { NgxUploaderModule } from 'ngx-uploader';
import { NgbModule, NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from '../services/datepicker/date-formatter.service';
import { CustomDatepickerI18nService, I18n } from '../services/datepicker/datepicker-i18n.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  imports: [
    TranslateModule.forChild({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
    }),
    CommonModule,
    NgxUploaderModule,
    NgbModule,
  ],
  declarations: [
  ],
  providers: [
    /*{
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },*/
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateCustomParserFormatter,
    },
    I18n,
    {
      provide: NgbDatepickerI18n,
      useClass: CustomDatepickerI18nService,
    },
  ],
  exports: [
    TranslateModule,
    NgxUploaderModule,
    NgbModule,
  ],
})
export class SharedModule {}
