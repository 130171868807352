import { PointOfInterestService } from 'app/services/point-of-interest.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
}
