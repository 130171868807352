import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { PasswordValidator } from './password-validation';
import { NbSpinnerService, NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-restore',
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.css'],
})
export class RestoreComponent implements OnInit, AfterViewInit {
  public myForm: FormGroup;
  public areEqual: boolean = true;
  public NotEqual: any = false;
  private token;
  public account_validation_messages = {
    'confirm_password': [
      { type: 'required' },
    ],
    'password': [
      { type: 'required' },
      { type: 'minlength' },
      { type: 'pattern' },
    ],
    'username': [
      { type: 'required' },
      { type: 'pattern' },
    ],
  };

  forgot: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService,
    private toastrService: NbToastrService,
    private spinner: NbSpinnerService,
  ) {
    this.translate.setDefaultLang('es');
   }

  ngAfterViewInit() {
    this.authService.userEmail(this.token).subscribe(response => {
      this.myForm.get('username').setValue(response);
      this.spinner.load();
    }, error => {
      this.toastrService.show(
        error, 'Error', { duration: 3000, status: 'danger' });
    });
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.paramMap.get('forgot')) {
      this.forgot = true;
    }
    this.token = this.activatedRoute.snapshot.paramMap.get('token');

    const json = {
      username: ['', Validators.compose([
        Validators.required,
        Validators.pattern('.+@.+\\..+'),
      ])],
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('[A-Za-z0-9]*([a-zA-Z]+[0-9]+|[0-9]+[a-zA-Z]+)'),
      ])],
      repassword: ['', Validators.compose([
        Validators.required,
        matchOtherValidator('password'),
      ])],
    };

    this.myForm = this.formBuilder.group(json);
    this.myForm.get('password').valueChanges.subscribe(response => {
      this.matchPassword();
    }, error => {

    });
    this.myForm.get('repassword').valueChanges.subscribe(response => {
      this.matchPassword();
    }, error => {

    });
  }

  submit() {
    this.areEqual = PasswordValidator.areEqual(this.myForm);
    if (this.token && this.myForm.valid && this.areEqual) {
      this.authService.resetPassword(this.myForm.get('password').value, this.token).subscribe(response => {
        const url = 'success';
        this.router.navigate([url]);
        this.toastrService.show(
          this.translate.instant('restore.resetSuccessMsg'),
          this.translate.instant('restore.resetSuccessTitle'),
          { duration: 3000, status: 'success'  });
      }, error => {
        const message = this.translate.instant('restore.areEqual');
        this.toastrService.show(
          message, 'Error', { duration: 3000, status: 'danger'  });
      });
    }
  }

  matchPassword() {
    const pass = this.myForm.get('password');
    const repass = this.myForm.get('repassword');
    if (pass && pass.value && repass && repass.value) {
      if ( pass.value === repass.value) {
        this.NotEqual = false;
      } else {
        this.NotEqual = true;
      }
    }
  }

  isValidUsername() {
    return !this.myForm.get('username').hasError('required') && !this.myForm.get('username').hasError('pattern');
  }

  forgotPassword() {
    this.authService.reset(this.myForm.get('username').value).subscribe(response => {
      if (response === 'Sending mail success') {
        this.toastrService.show(
          this.translate.instant('restore.sendMailSuccessMsg') + this.myForm.get('username').value,
          this.translate.instant('restore.resetSuccessTitle'),
          { duration: 3000, status: 'success'  });
      } else if (response === 'no user') {
        this.toastrService.show(
          this.translate.instant('restore.mail-not-found'),
          this.translate.instant('restore.mail-not-found-title'),
          { duration: 3000, status: 'danger'  });
      }
    }, error => {
      const message = this.translate.instant('restore.areEqual');
      this.toastrService.show(
        message, 'Error', { duration: 3000, status: 'danger'  });
    });
  }

  cancel() {
    this.router.navigate(['/login']);
  }

}

export function matchOtherValidator(otherControlName: string) {

  let thisControl: FormControl;
  let otherControl: FormControl;

  return function matchOtherValidate(control: FormControl) {

    if (!control.parent) {
      return null;
    }

    // Initializing the validator.
    if (!thisControl) {
      thisControl = control;
      otherControl = control.parent.get(otherControlName) as FormControl;
      if (!otherControl) {
        throw new Error('matchOtherValidator(): other control is not found in parent group');
      }
      otherControl.valueChanges.subscribe(() => {
        thisControl.updateValueAndValidity();
      });
    }
    if (!otherControl) {
      return null;
    }
    if (otherControl.value !== thisControl.value) {
      return {
        matchOther: true,
      };
    }
    return null;
  };
}
