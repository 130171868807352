import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root',
})
export class DataApiService {

  constructor(
    private http: HttpClient,
  ) { }

  @Output() progressBar: EventEmitter<any> = new EventEmitter();
  @Output() showBar: EventEmitter<any> = new EventEmitter();

  emitProgressBar(event) {
      this.progressBar.emit(event);
  }

  emitShowBar(event) {
      this.showBar.emit(event);
  }

  getCategoriesEvent() {
    const url = environment.rootpath + 'ext/categories-service/categories-event';
    return this.http.get<any>(url)
    .pipe(map((res) => {
      return res;
    }));
  }

  getCategoriesPOI() {
    const url = environment.rootpath + 'ext/categories-service/categories-poi';
    return this.http.get<any>(url)
    .pipe(map((res) => {
      return res;
    }));
  }

  getCategoriesEntity(categoryType) {
    const url = environment.rootpath + 'ext/categories-service/categories-entity/' + categoryType;
    return this.http.get<any>(url)
    .pipe(map((res) => {
      return res;
    }));
  }

  getSubategories(categoryId) {
    const url = environment.rootpath + 'ext/categories-service/' + categoryId + '/subcategories';
    return this.http.get<any>(url)
    .pipe(map((res) => {
      return res;
    }));
  }

  getDisplays() {
    const url = environment.rootpath + 'ext/display-service/all-display';
    return this.http.get<any>(url)
    .pipe(map((res) => {
      return res;
    }));
  }

  reverseGeosearch(lat, lng) {
    const url = 'https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=' + lat + '&lon=' + lng;
    return this.http.get<any>(url)
    .pipe(map((res) => {
      return res;
    }));
  }

  getCountries() {
    const url = environment.rootpath + 'ext/public-user-services/countries';
    return this.http.get<any>(url)
    .pipe(map((res) => {
      return res;
    }));
  }

  getProvinces() {
    const url = environment.rootpath + 'ext/public-user-services/provinces';
    return this.http.get<any>(url)
    .pipe(map((res) => {
      return res;
    }));
  }

  getGenres() {
    const url = environment.rootpath + 'ext/public-user-services/genres';
    return this.http.get<any>(url)
    .pipe(map((res) => {
      return res;
    }));
  }

  getCurrencyValue() {
    const url = environment.rootpath + 'ext/public-info-service/currency';
    return this.http.get<any>(url)
    .pipe(map((res) => {
      return res;
    }));
  }

  getVersion() {
    const url = environment.rootpath + 'ext/public-info-service/version';
    return this.http.get<any>(url)
    .pipe(map((res) => {
      return res;
    }));
  }

}
