<nb-card>
    <!-- <nb-card-header>{{ 'info.label.about' | translate }}</nb-card-header> -->
    <nb-card-body>
        <div class="row text-center justify-content-center">
            <div class="col-12">
                <img class="smartTour-logo" src="../../../../assets/images/isotipo-degrade.png">
            </div>
            <div class="col-12">
                <span class="smartTour-title"> {{ 'info.label.smart-tour' | translate }}</span>
            </div>
        </div>
        <div class="row mt-3 text-center justify-content-center">
            <div class="col-12">
                <span>{{ 'info.label.version' | translate }} {{version}}</span>
            </div>
            <div class="col-12">
                <span>{{ 'info.label.verson-date' | translate }} {{versionDate}}</span>
            </div>
        </div>
        <div class="row mt-5 text-center justify-content-center">
            <div class="col-12">
                <span>{{ 'info.label.developed-by' | translate }}</span>
            </div>
            <div class="col-12">
                <img class="estratec-logo" src="../../../../assets/images/logo-estratec.png">
            </div>
        </div>
        <div class="row mt-1 text-center justify-content-center">
            <div class="col-12">
                <span>{{ 'info.label.software' | translate }}</span>
                <img class="flag" src="../../../../assets/images/bandera.png">
            </div>
        </div>
        <div class="row mt-5 text-center justify-content-center">
            <div class="col-12">
                <a class="text-15" href="http://www.estratec.com.ar">{{'info.label.estratec-url' | translate}}</a>
            </div>
        </div>
    </nb-card-body>
    <!-- <nb-card-footer>
        <button class="m-1 float-right" nbButton type="button" status="success" (click)="dialogRef.close()">{{'common.label.ok' | translate}}</button>
    </nb-card-footer> -->
</nb-card>