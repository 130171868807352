import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';

import { VerifiedComponent } from './pages/verified/verified.component';

import { LoginComponent } from './pages/login/login.component';
import { RestoreComponent } from './pages/restore/restore.component';
import { AuthGuard } from './auth-guard.service';
import { SuccessComponent } from './pages/success/success.component';
import { TocComponent } from './pages/toc/toc.component';

export const routes: Routes = [
  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'restore',
        component: RestoreComponent,
      },
    ],
  },
  {
    path: 'restore/:token',
    component: RestoreComponent,
  },
  {
    path: 'success',
    component: SuccessComponent,
  },
  {
    path: 'toc',
    component: TocComponent,
  },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
