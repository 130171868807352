import { Component, OnInit } from '@angular/core';
import { NbSpinnerService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {

  constructor(
    private translate: TranslateService,
    private spinner: NbSpinnerService,
  ) {
    this.translate.setDefaultLang('es');
    this.spinner.load();
   }

  ngOnInit() {
    }
}
