<div class="row w-100">
  <div class="col-12">
    <div *ngFor="let progressbar of progresbars">
      <label class="label">{{ 'common.label.' + progressbar.operation | translate }} -</label>
      <label class="label"> - {{ 'menu.' + progressbar.type + '.menu.title' | translate }} -</label>
      <label class="label">- {{ progressbar.name }}</label>
      <div class="row">
        <div class="offset-1 col-9">
          <nb-progress-bar *ngIf="progressbar && progressbar.status !== 'error'" [value]="progressbar.progress" status="success">{{progressbar.progress}} %</nb-progress-bar>
          <nb-progress-bar *ngIf="progressbar && progressbar.status === 'error'" [value]="progressbar.progress" status="danger">{{progressbar.progress}} %</nb-progress-bar>
        </div>
        <div class="col-1">
          <nb-icon *ngIf="progressbar && progressbar.status === 'ok'" status="success" nbTooltip="{{ 'common.label.ok' | translate }}" icon="checkmark-circle-outline" pack="eva"></nb-icon>
          <nb-icon *ngIf="progressbar && progressbar.status === 'error'" status="danger" nbTooltip="{{ 'common.label.error' | translate }}" icon="alert-triangle-outline" pack="eva"></nb-icon>
        </div>
        <div class="col-1">
          <button type="button" nbTooltip="{{ 'common.buttons.close' | translate }}" nbButton status="danger" size="tiny" (click)="closeBar(progressbar)">
            <nb-icon icon="close-outline" pack="eva"></nb-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>