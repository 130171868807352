<div class="row justify-content-center align-items-center">
  <img class="banner" src="../../../assets/images/LoginBanner.png">
</div>
<div class="row justify-content-center align-items-center">
  <img class="logo" src="../../../assets/images/isotipo-dark.png">
</div>
<div class="container h-100 pt-sm-5">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="col-sm-12 inner">

      <div id="welcome" *ngIf="!forgot">
        <div class="col-12 d-flex justify-content-center">
          <h1 class="welcome-title">{{ 'restore.label.new-password-title' | translate | uppercase }}</h1>
        </div>
        <div class="col-12 d-flex justify-content-center">
          <h2 class="welcome-subtitle">{{ 'restore.label.password-validation' | translate }}</h2>
        </div>
      </div>
      <div id="welcome" *ngIf="forgot">
        <div class="col-12 d-flex justify-content-center">
          <h1 class="welcome-title">{{ 'restore.label.recover-password-title' | translate | uppercase }}</h1>
        </div>
      </div>
      <form [formGroup]="myForm">
        <div id="inputs">
          <div class="row not-margin justify-content-center" style="text-align: center;">
            <div *ngIf="forgot" class="full-width">
              <input matInput class="full-width input" type="text" placeholder="{{ 'login.label.username' | translate }}"
                formControlName="username" required>
              <div *ngFor="let validation of account_validation_messages.username">
                <div class="error-message"
                  *ngIf="myForm.get('username').hasError(validation.type) && (myForm.get('username').dirty || myForm.get('username').touched)">
                  {{ 'restore.mail.' + validation.type | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row not-margin justify-content-center" style="text-align: center;">
            <div *ngIf="!forgot" class="full-width">
              <input matInput class="full-width input" type="password" placeholder="Contraseña" formControlName="password" required>
              <div *ngFor="let validation of account_validation_messages.password">
                <div class="error-message"
                  *ngIf="myForm.get('password').hasError(validation.type) && (myForm.get('password').dirty || myForm.get('password').touched)">
                  {{ 'restore.' + validation.type | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row not-margin justify-content-center" style="text-align: center;">
            <div *ngIf="!forgot" class="full-width">
              <input matInput class="full-width input" type="password" placeholder="Repetir contraseña" formControlName="repassword" required>
              <div *ngFor="let validation of account_validation_messages.confirm_password">
                <div class="error-message"
                  *ngIf="myForm.get('repassword').hasError(validation.type) && (myForm.get('repassword').dirty || myForm.get('repassword').touched)">
                  {{ 'restore.' + validation.type | translate }}
                </div>
              </div>
              <div *ngIf="NotEqual" class="error-message">
                {{ 'restore.areEqual' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="!forgot" id="buttons" class="col-12 d-flex justify-content-center no-padding">
          <button type="button" class="btn btn-primary float-right mb-3 send-button" role="button" [disabled]="!myForm.valid"
            (click)="submit()">{{ 'common.buttons.send' | translate }}</button>
        </div>

        <div *ngIf="forgot" id="buttons" class="row col-12 justify-content-center">
          <div class="col-6">
            <button type="reset" class="btn btn-primary float-right cancel-button" role="button" (click)="cancel()">
              {{ 'common.buttons.cancel' | translate }}
            </button>
          </div>
          <div class="col-6">
            <button type="button" class="btn btn-primary float-left send-button" role="button"
              [disabled]="!isValidUsername()" (click)="forgotPassword()">{{ 'common.buttons.send' | translate
              }}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>