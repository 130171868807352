import { DataApiService } from 'app/services/data-api.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive start>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed [hidden]="showBar">
        <ngx-load-bar></ngx-load-bar>
      </nb-layout-footer>

      <nb-layout-footer>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements OnInit {

  showBar: boolean = true;

  constructor(
    private dataApiService: DataApiService,
  ) {
  }

  ngOnInit(): void {
    this.dataApiService.showBar.subscribe((result) => {
      if (result) {
        this.showBar = false;
      } else {
        this.showBar = true;
      }
    });
  }
}
