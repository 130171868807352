import { Component, OnInit, Inject } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-dialog-alert',
  templateUrl: './dialog-alert.component.html',
  styleUrls: ['./dialog-alert.component.scss'],
})
export class DialogAlertComponent implements OnInit {

  constructor(
    public dialogRef: NbDialogRef<DialogAlertComponent>,
    ) { }

  title;
  text;
  okButton;
  cancelButton;
  response = false;

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ok() {
    this.response = true;
    this.dialogRef.close(this.response);
  }

}
