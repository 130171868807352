import { DataApiService } from 'app/services/data-api.service';
import { Component, OnInit, Inject } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-dialog-info',
  templateUrl: './dialog-info.component.html',
  styleUrls: ['./dialog-info.component.scss'],
})
export class DialogInfoComponent implements OnInit {

  version;
  versionDate;

  constructor(
    public dialogRef: NbDialogRef<DialogInfoComponent>,
    private dataApiService: DataApiService,
    ) { }

  ngOnInit() {
    this.dataApiService.getVersion().subscribe(version => {
      if (version) {
        this.version = version.version;
        this.versionDate = version.versionDate;
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

}
